// colors
$color-background: #0D083E;
$color-background-social: #1F1D5A;
$color-text-disabled: #685B9D;
$color-menu: #0B0A2A;
$color-text: #FFF;
$color-date: #84799F;
$color-box-shadow: 0px 0px 7.7px 2px rgba(61, 139, 164, 0.25) inset, 0px 3px 16px 0px rgba(0, 0, 0, 0.30), 4px 1px 9.6px 0px #114077 inset;
$color-border: #000;
$color-contact-bg: #4E4375;
$color-gradient-primary: linear-gradient(174.35deg, #5EFF82 16.08%, #39F262 95.29%);
$color-gradient-primary-second: linear-gradient(182.46deg, #CBFFA1 2.17%, #31BF50 98.05%);
$color-gradient-secondary: linear-gradient(174.35deg, #EA995B 16.08%, #DB8B4A 95.29%);
$color-gradient-secondary-second: linear-gradient(182.46deg, #FFC975 2.17%, #B0671D 98.05%);
$color-gradient-loyalty: radial-gradient(50% 50% at 50% 50%, #282573 24.5%, #0D083E 91%);
$color-primary-shadow: #148A67;
$color-secondary-shadow: #6F3117;
$color-primary: #39f285;
$color-secondary: #EA995B;
$color-inactive: #9E9E9E;
$color-divider: #302F82;
$color-lang-gradient: linear-gradient(251.18deg, #15135B 16.14%, #2F2D81 95.93%);
$color-skeleton-gradient: linear-gradient(139.04deg, #2F2D81 0%, #15135B 98.23%);
$color-box-gradient: linear-gradient(139deg, #2F2D81 0%, #15135B 98.23%);
$color-background-hover: rgba(47, 38, 88, .8) center center no-repeat;
$color-background-opacity: rgba(246, 246, 248, .3) center center no-repeat;
$color-badge: #28275A;
$color-provider: #949CD1;
$color-unlock: #858EDD;
$color-radio-border: #31629B;
$input-border-gradient: linear-gradient(90deg, #31629B 0%, #2B2971 100%);
$input-border-gradient-error: linear-gradient(91.04deg, #FF8E75 11.7%, #CC473F 81.66%);
$input-shadow-color: #1C1B60;
$input-inner-shadow-color: rgba(71, 247, 247, 0.6);
$filter-icon-background: url(/assets/svg/filter-bg.svg);
$category-bg: url(/assets/svg/category-bg.svg);
$forbidden-bg: url(/assets/img/technical-pages/403-bg.png);
$forbidden-bg-mobile: url(/assets/img/technical-pages/403-bg-mob.png);
$not-found-bg: url(/assets/img/technical-pages/404-bg.png);
$not-found-bg-mobile: url(/assets/img/technical-pages/404-bg-mob.png);
$category-bg: url(/assets/svg/category-bg.svg);
$loyalty-bg: url(/assets/img/loyalty/loyalty-bg.png);
$loyalty-bg-mob: url(/assets/img/loyalty/loyalty-bg-mobile.png);
$color-gradient-text: #69FE8D;
$color-success: #6DF37D;
$color-error: #F58675;
$color-table-border: #5D5784;
$color-disabled: #9099BE;
$payments-gradient: linear-gradient(90deg, rgba(7, 4, 28, 0) 0%, #091916 100%);

// vars
$color-date-var: --color-date;
$color-box-shadow-var: --color-box-shadow;
$color-text-disabled-var: --color-text-disabled;
$color-box-gradient-var: --color-box-gradient;
$color-contact-bg-var: --color-contact-bg;
$color-border-var: --color-border;
$color-background-var: --color-background;
$color-background-social-var: --color-background-social;
$color-menu-var: --color-menu;
$color-text-var: --color-text;
$color-gradient-primary-var: --color-gradient-primary;
$color-gradient-primary-second-var: --color-gradient-primary-second;
$color-gradient-secondary-var: --color-gradient-secondary;
$color-gradient-secondary-second-var: --color-gradient-secondary-second;
$color-gradient-loyalty-var: --color-gradient-loyalty;
$color-primary-shadow-var: --color-primary-shadow;
$color-secondary-shadow-var: --color-secondary-shadow;
$color-primary-var: --color-primary;
$color-secondary-var: --color-secondary;
$color-divider-var: --color-divider;
$color-lang-gradient-var: --color-lang-gradient;
$color-skeleton-gradient-var: --color-skeleton-gradient;
$color-gradient-box-shadow-var: --color-gradient-box-shadow;
$color-background-hover-var: --color-background-hover;
$color-background-opacity-var: --color-background-opacity;
$color-badge-var: --color-badge;
$color-provider-var: --color-provider;
$color-unlock-var: --color-unlock;
$color-radio-border-var: --color-radio-border;
$filter-icon-background-var: --filter-icon-background;
$category-bg-var: --category-bg;
$input-border-gradient-var: --input-border-gradient;
$input-border-gradient-error-var: --input-border-gradient-error;
$input-shadow-color-var: --input-shadow-color;
$input-inner-shadow-color-var: --input-inner-shadow-color;
$forbidden-bg-var: --forbidden-bg;
$forbidden-bg-mobile-var: --forbidden-bg-mobile;
$not-found-bg-var: --not-found-bg;
$not-found-bg-mobile-var: --not-found-bg-mobile;
$color-gradient-text-var: --color-gradient-text;
$color-success-var: --color-success;
$color-error-var: --color-error;
$color-secondary-var: --color-secondary;
$color-inactive-var: --color-inactive;
$color-input-active-var: --color-input-active;
$payments-gradient-var: --payments-gradient;
$loyalty-bg-var: --loyalty-bg;
$loyalty-bg-mob-var: --loyalty-bg-mob;
$color-table-border-var: --color-table-border;
$color-disabled-var: --color-disabled;

// generated by https://responsive-css.spritegen.com/
$theme-features-icon-var: --theme-features-icon;
$theme-quotes-icon-var: --theme-quotes-icon;


$theme-map-default-dark: (
  $color-table-border-var: $color-table-border,
  $color-date-var: $color-date,
  $color-box-shadow-var: $color-box-shadow,
  $color-text-disabled-var: $color-text-disabled,
  $color-box-gradient-var: $color-box-gradient,
  $color-contact-bg-var: $color-contact-bg,
  $color-border-var: $color-border,
  $color-background-var: $color-background,
  $color-background-social-var: $color-background-social,
  $color-menu-var: $color-menu,
  $color-text-var: $color-text,
  $color-gradient-primary-var: $color-gradient-primary,
  $color-gradient-primary-second-var: $color-gradient-primary-second,
  $color-gradient-secondary-var: $color-gradient-secondary,
  $color-gradient-secondary-second-var: $color-gradient-secondary-second,
  $color-gradient-loyalty-var: $color-gradient-loyalty,
  $color-primary-shadow-var: $color-primary-shadow,
  $color-secondary-shadow-var: $color-secondary-shadow,
  $color-primary-var: $color-primary,
  $color-secondary-var: $color-secondary,
  $color-divider-var: $color-divider,
  $color-lang-gradient-var: $color-lang-gradient,
  $color-skeleton-gradient-var: $color-skeleton-gradient,
  $color-background-hover-var: $color-background-hover,
  $color-background-opacity-var: $color-background-opacity,
  $color-badge-var: $color-badge,
  $color-provider-var: $color-provider,
  $color-unlock-var: $color-unlock,
  $color-radio-border-var: $color-radio-border,
  $filter-icon-background-var: $filter-icon-background,
  $category-bg-var: $category-bg,
  $forbidden-bg-var: $forbidden-bg,
  $forbidden-bg-mobile-var: $forbidden-bg-mobile,
  $not-found-bg-var: $not-found-bg,
  $not-found-bg-mobile-var: $not-found-bg-mobile,
  $input-border-gradient-var: $input-border-gradient,
  $input-border-gradient-error-var: $input-border-gradient-error,
  $input-shadow-color-var: $input-shadow-color,
  $input-inner-shadow-color-var: $input-inner-shadow-color,
  $color-gradient-box-shadow-var: (0 0 5px -2px #6BFFDC, 0 0 7px 0 #65FF91, 0 0 24px 3px #84ED62),
  $color-gradient-text-var: $color-gradient-text,
  $color-success-var: $color-success,
  $color-error-var: $color-error,
  $color-inactive-var: $color-inactive,
  $loyalty-bg-var: $loyalty-bg,
  $loyalty-bg-mob-var: $loyalty-bg-mob,
  $color-disabled-var: $color-disabled,
);

$theme-map-holland: (
  $color-background-var: $color-background,
  $color-gradient-secondary-var: linear-gradient(222.83deg, #FFDD66 3.51%, #FA9B66 50.25%, #FA9B66 57.28%, #FF7C33 100%),
  $color-gradient-text-var: #FF9559,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
);

$theme-map-uk: (
  $color-background-var: $color-background,
  $color-gradient-secondary-var: linear-gradient(225deg, #FFCD92 0%, #FB8398 51.04%, #BC9CFF 100%),
  $color-gradient-text-var: #EF7CA7,
  $color-success-var: #00BB83,
  $color-error-var: #FD387F,
  $color-primary-var: #fff,
  $color-secondary-var: #58607D,
);

// fonts
$font-default: "Inter";

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;
$theme-transition-duration: 1500ms;

// breakpoints
$bp-xs-ios: 340px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;
