@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == account {
  $char: "\E001";
}
  @if $filename == android {
  $char: "\E002";
}
  @if $filename == apple {
  $char: "\E003";
}
  @if $filename == arrow {
  $char: "\E004";
}
  @if $filename == attention {
  $char: "\E005";
}
  @if $filename == bonus {
  $char: "\E006";
}
  @if $filename == check {
  $char: "\E007";
}
  @if $filename == cherry {
  $char: "\E008";
}
  @if $filename == close-game {
  $char: "\E009";
}
  @if $filename == close {
  $char: "\E00A";
}
  @if $filename == delete {
  $char: "\E00B";
}
  @if $filename == edit {
  $char: "\E00C";
}
  @if $filename == exclusive {
  $char: "\E00D";
}
  @if $filename == favorite-filled {
  $char: "\E00E";
}
  @if $filename == favorite {
  $char: "\E00F";
}
  @if $filename == few-games {
  $char: "\E010";
}
  @if $filename == filter {
  $char: "\E011";
}
  @if $filename == full-screen {
  $char: "\E012";
}
  @if $filename == heart {
  $char: "\E013";
}
  @if $filename == help {
  $char: "\E014";
}
  @if $filename == hot {
  $char: "\E015";
}
  @if $filename == informer {
  $char: "\E016";
}
  @if $filename == install-app {
  $char: "\E017";
}
  @if $filename == like-filled {
  $char: "\E018";
}
  @if $filename == like {
  $char: "\E019";
}
  @if $filename == lock {
  $char: "\E01A";
}
  @if $filename == locked {
  $char: "\E01B";
}
  @if $filename == lottery {
  $char: "\E01C";
}
  @if $filename == loyalty {
  $char: "\E01D";
}
  @if $filename == missions {
  $char: "\E01E";
}
  @if $filename == new {
  $char: "\E01F";
}
  @if $filename == one-game {
  $char: "\E020";
}
  @if $filename == password {
  $char: "\E021";
}
  @if $filename == play {
  $char: "\E022";
}
  @if $filename == pointer-down {
  $char: "\E023";
}
  @if $filename == popular {
  $char: "\E024";
}
  @if $filename == question {
  $char: "\E025";
}
  @if $filename == search-nav {
  $char: "\E026";
}
  @if $filename == search {
  $char: "\E027";
}
  @if $filename == settings {
  $char: "\E028";
}
  @if $filename == tournament {
  $char: "\E029";
}
  @if $filename == wheel {
  $char: "\E02A";
}
  @if $filename == windows {
  $char: "\E02B";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-account {
  @include icon(account);
}
.icon-android {
  @include icon(android);
}
.icon-apple {
  @include icon(apple);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-attention {
  @include icon(attention);
}
.icon-bonus {
  @include icon(bonus);
}
.icon-check {
  @include icon(check);
}
.icon-cherry {
  @include icon(cherry);
}
.icon-close-game {
  @include icon(close-game);
}
.icon-close {
  @include icon(close);
}
.icon-delete {
  @include icon(delete);
}
.icon-edit {
  @include icon(edit);
}
.icon-exclusive {
  @include icon(exclusive);
}
.icon-favorite-filled {
  @include icon(favorite-filled);
}
.icon-favorite {
  @include icon(favorite);
}
.icon-few-games {
  @include icon(few-games);
}
.icon-filter {
  @include icon(filter);
}
.icon-full-screen {
  @include icon(full-screen);
}
.icon-heart {
  @include icon(heart);
}
.icon-help {
  @include icon(help);
}
.icon-hot {
  @include icon(hot);
}
.icon-informer {
  @include icon(informer);
}
.icon-install-app {
  @include icon(install-app);
}
.icon-like-filled {
  @include icon(like-filled);
}
.icon-like {
  @include icon(like);
}
.icon-lock {
  @include icon(lock);
}
.icon-locked {
  @include icon(locked);
}
.icon-lottery {
  @include icon(lottery);
}
.icon-loyalty {
  @include icon(loyalty);
}
.icon-missions {
  @include icon(missions);
}
.icon-new {
  @include icon(new);
}
.icon-one-game {
  @include icon(one-game);
}
.icon-password {
  @include icon(password);
}
.icon-play {
  @include icon(play);
}
.icon-pointer-down {
  @include icon(pointer-down);
}
.icon-popular {
  @include icon(popular);
}
.icon-question {
  @include icon(question);
}
.icon-search-nav {
  @include icon(search-nav);
}
.icon-search {
  @include icon(search);
}
.icon-settings {
  @include icon(settings);
}
.icon-tournament {
  @include icon(tournament);
}
.icon-wheel {
  @include icon(wheel);
}
.icon-windows {
  @include icon(windows);
}
