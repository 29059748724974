@use 'variables';
@use 'mixins';

:root.default-dark {
  @include mixins.spread-map(variables.$theme-map-default-dark);
}

// :root.holland {
//   @include spread-map($theme-map-holland);
// }

// :root.uk {
//   @include spread-map($theme-map-uk);
// }


:root.theme-transition,
:root.theme-transition * {
  transition: background-color variables.$theme-transition-duration;
  transition-delay: 0s;
}
